import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Virego = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #927A48, #A68C59, #BDA26D, #A68C59, #927A48 )",
        icons: "#caae76",
        navClass: "virego",
      }}
      seo={{
        title: "Virego",
        headerTitle: "virego",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/virego_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/virego/",
      }}
    >
      {/* eslint-disable jsx-a11y/media-has-caption */}
      <PortfolioHeader name="virego" height="86" />
      <section className="container-fluid virego_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Virego</h1>
              <ul>
                <li>Logo</li>
                <li>Brand Book</li>
                <li>Strona internetowa</li>
                <li>Materiały promocyjne</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                VIREGO® działa na rynku usług biznesowych od 2012 roku. Podstawą
                działalności firmy jest ogólnoświatowe wsparcie i rozwój
                przedsiębiorców w różnych procesach i obszarach biznesowych.
                Dzięki międzynarodowej sieci partnerów obejmującej ponad 11
                krajów i 200 specjalistów VIREGO® rozwiązuje najbardziej złożone
                problemy klientów i aktywnie uczestniczy w rozwoju wielu firm,
                świadcząc usługi concierge.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid virego_section_3">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/virego_main_1.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/virego_main_2.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Black CMYK</p>
                    <h5>
                      <span>0</span>
                      <span>0</span>
                      <span>0</span>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Gold CMYK</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 22 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 28 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 62 : 0}
                      />
                      <CountUp start={0} duration={3} end={isVisible ? 1 : 0} />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                </h5>
              </div>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 203 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 176 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 118 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>000000</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>cbb076</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="virego_section_4">
        <h4>Identyfikacja wizualna</h4>
      </section>
      <section className="virego_section_5">
        <img
          src={require("../../assets/img/portfolio/virego_main_3.png")}
          alt=""
        />
      </section>
      <section className="virego_section_6">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="inner">
              <h4>Strona internetowa</h4>
              <p>
                Dokonaliśmy całkowitego rebrandingu marki VIREGO®. Stworzyliśmy
                nowe formy biznesowe, które odzwierciedlają globalne ambicje
                firmy, w tym logo, Brand Book, byliśmy również odpowiedzialni za
                przygotowanie wszystkich elementów identyfikacji wizualnej
                firmy, a także za nowoczesną stronę internetową wraz z
                wykonaniem animacji.
              </p>
              <p>
                Projekt został rozwinięty o nową markę a stronę projektu można
                zobaczyć pod adresem Voeld.com.
              </p>
              <br />
              <a
                href="https://voeld.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn_custom"
              >
                Zobacz realizację
              </a>
            </div>
          </div>
          <div className="col-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/virego_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="virego_section_7">
        <img
          src={require("../../assets/img/portfolio/virego_main_5.png")}
          alt=""
        />
      </section>
      <section className="virego_section_8">
        <img
          src={require("../../assets/img/portfolio/virego_main_6.png")}
          alt=""
        />
      </section>
      <section className="virego_section_9">
        <img
          src={require("../../assets/img/portfolio/virego_main_7.png")}
          alt=""
        />
      </section>
      <section className="virego_section_10">
        <video loop autoplay="true">
          <source
            src="https://virego.pl/wp-content/themes/virego/video/VIREGO_WOMAN_v4.mp4"
            type="video/mp4"
          />
        </video>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default Virego;
